// extracted by mini-css-extract-plugin
export var resource_subgroup = "resource-details-module--resource_subgroup--qhFhU";
export var resource_subgroup_header = "resource-details-module--resource_subgroup_header--jI9ZX";
export var resource_article = "resource-details-module--resource_article--whoQ5";
export var green_line_left = "resource-details-module--green_line_left--enzWl";
export var resource_article_content = "resource-details-module--resource_article_content--iZlSD";
export var resource_article_details = "resource-details-module--resource_article_details--p1qzu";
export var resource_article_text = "resource-details-module--resource_article_text--xgOqC";
export var resource_linkdiv = "resource-details-module--resource_linkdiv--K0jGq";
export var resource_external_hyperlink = "resource-details-module--resource_external_hyperlink--FPxca";
export var resource_picture_frame = "resource-details-module--resource_picture_frame--EocIP";